.races {
    box-sizing: border-box;
    width: 100%;
}

.race {
    box-sizing: border-box;
    justify-content: space-between;
    border-bottom: 0;
    display: flex;
    transition: all .3s ease-in-out;

    transform: scaleY(1);
    height: auto;
    background: linear-gradient(135deg, #f4d166 0%, #f99d82 100%);
    color: #2e2828;
    padding: 10px 19px;
    border-radius: 5px;
    margin: 5px 0 0;
}

.race-header .race__time,
.race-header .race__title {
    display: block;
}

.race__title {
    font-size: 1.17em;
    margin: 0;
}

.race__time {
    font-size: 1.1em;
    margin-left: 1em;
}

.race__time,
.race__title {
    transition: all .2s ease-in-out;
}