body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f2eee2;
    box-sizing: border-box;
    font-size: 16px;
}

/*
Beige #F2EEE2
Cyan #43C0F6
Yellow #F9CF00
Red #e95327
Orange #f99d82
 */

a {
    color: #000;
    cursor: pointer;
}
ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

h1 {
    text-align: center;
}

.pace-calculator {
    text-align: center;
}

.page {
    padding: 0 20px;
    text-align: left;
    max-width: 980px;
    margin: 0 auto;
}

.dashboard {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 20px;
}

.pace-selector {
    width: 125px;
    height: 125px;
    border: 5px inset #f99d82;
    border-radius: 63px;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    box-sizing: border-box;
    color: #000;
    font-weight: 700;
    font-size: 28px;
    font-family: sans-serif;
    position: relative;
    margin-bottom: 20px;
}

.pace-selector:after {
    content: " ";
    border-top: 9px solid transparent;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 9px solid #43c0f6;
    position: absolute;
    left: 50px;
    top: 10px;
}

.pace-selector:before {
    content: " ";
    border-top: 9px solid #43c0f6;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 9px solid transparent;
    position: absolute;
    bottom: 10px;
    left: 50px;
}
.pace-selector.approx {
    border: 5px inset #d9d9d9;
    opacity: 0.6;
}
.approx-sign {
    position: absolute;
    color: #e95327;
    left: 7px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}
.approx .approx-sign {
    opacity: 1;
}

select {
    appearance: none;
    outline: 0;
    box-shadow: none;
    border: 0 !important;
    background-image: none;
    font-size: 28px;
    font-family: sans-serif;
    position: relative;
    background-color: transparent;
    text-align: center;
    text-align-last: center;
}

.pace-unit {
    color: #e95327;
    font-size: 0.5em;
}

.pace-literal {
    font-size: 1.6em;
}

.app-version {
    margin: 20px 0;
    font-size: 0.8em;
}

.banner {
    width: 100%;
}

.banner--cookies {
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(10px);
    font-size: 0.8em;
    position: fixed;
    bottom: 0;
    padding: 5px;
}

.button {
    padding: 10px 15px;
    border: 1px solid #f99d82;
    background-color: transparent;
    border-radius: 4px;
}

.button--close {
    margin: 5px 0;
}

.text-center {
    text-align: center;
}

@media screen and (min-width: 768px) {
    .pace-selector,
    .dashboard {
        margin-bottom: 40px;
    }
}
